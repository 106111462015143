import { useMediaQuery } from '@cocoonspace/shared/hooks/use-media-query.hook'
import { dateTimeUtils } from '@cocoonspace/shared/utils/date-time-utils'
import { ArrowRight, Calendar as CalendarIcon, Trash2 } from 'lucide-react'
import { useState } from 'react'
import { buttonVariants } from '~/components/ui/button'
import { Calendar } from '~/components/ui/calendar'
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from '~/components/ui/popover'
import { cn } from '~/lib/utils'

export interface Interval {
	start?: string
	end?: string
}

interface DatePickerProps {
	value?: Interval
	onChange: (value?: Interval) => void
}

const DatePlaceholder = ({ date }: { date?: string }) =>
	!!date && dateTimeUtils(date).isValid() ? (
		dateTimeUtils(date).format('DD/MM/YYYY')
	) : (
		<span className='text-foreground/40'>{'__/__/____'}</span>
	)

export const RangePicker = ({ value, onChange }: DatePickerProps) => {
	const [isOpen, setIsOpen] = useState(false)

	const isMobile = useMediaQuery('(max-width: 768px)')

	return (
		<Popover
			open={isOpen}
			onOpenChange={setIsOpen}
		>
			<div className='relative'>
				<PopoverTrigger
					// className='flex flex-1 flex-row items-center justify-start'
					className={buttonVariants({
						variant: 'outline',
						className: cn(
							'!flex !justify-start w-full flex-1 flex-row items-center pr-8 text-left font-normal capitalize',
							!value && 'text-muted-foreground',
						),
					})}
				>
					<CalendarIcon className='mr-2 h-4 w-4' />

					<DatePlaceholder date={value?.start} />

					<ArrowRight
						size={16}
						className='mx-2'
					/>

					<DatePlaceholder date={value?.end} />
				</PopoverTrigger>

				{(!!value?.start || !!value?.end) && (
					<button
						type='button'
						className='absolute top-0 right-0 ml-auto h-full px-2'
						onClick={() => onChange({ start: '', end: '' })}
					>
						<Trash2 className='h-4 w-4' />
					</button>
				)}
			</div>

			<PopoverContent className='w-auto p-0'>
				<Calendar
					mode='range'
					numberOfMonths={isMobile ? 1 : 2}
					selected={{
						from: value?.start ? new Date(value?.start) : undefined,
						to: value?.end ? new Date(value?.end) : undefined,
					}}
					onSelect={(range) => {
						onChange({
							start: range?.from
								? dateTimeUtils(range?.from)?.customFormat('dateTech')
								: undefined,
							end: range?.to
								? dateTimeUtils(range?.to)?.customFormat('dateTech')
								: undefined,
						})
					}}
				/>
			</PopoverContent>
		</Popover>
	)
}
