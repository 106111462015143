import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

// ------------------------------------------------------------
export type DateRangeShorthand =
	| 'all'
	| 'day'
	| '7d'
	| '30d'
	| 'month'
	| '6mo'
	| '12mo'
	| 'year'

export type DateTimeRange = [string, string]

export type DateRange = [string, string]

export type Dimensions =
	| SimpleFilterDimensions
	| CustomPropertyFilterDimensions
	| GoalDimension
	| TimeDimensions

export type SimpleFilterDimensions =
	| 'event:name'
	| 'event:page'
	| 'event:hostname'
	| 'visit:source'
	| 'visit:channel'
	| 'visit:referrer'
	| 'visit:utm_medium'
	| 'visit:utm_source'
	| 'visit:utm_campaign'
	| 'visit:utm_content'
	| 'visit:utm_term'
	| 'visit:screen'
	| 'visit:device'
	| 'visit:browser'
	| 'visit:browser_version'
	| 'visit:os'
	| 'visit:os_version'
	| 'visit:country'
	| 'visit:region'
	| 'visit:city'
	| 'visit:country_name'
	| 'visit:region_name'
	| 'visit:city_name'
	| 'visit:entry_page'
	| 'visit:exit_page'
	| 'visit:entry_page_hostname'
	| 'visit:exit_page_hostname'

export type CustomPropertyFilterDimensions = string

export type GoalDimension = 'event:goal'

export type TimeDimensions =
	| 'time'
	| 'time:month'
	| 'time:week'
	| 'time:day'
	| 'time:hour'

export type FilterTree = FilterEntry | FilterAndOr | FilterNot

export type FilterEntry = FilterWithoutGoals | FilterWithGoals

export type FilterWithoutGoals = [
	FilterOperationWithoutGoals,
	SimpleFilterDimensions | CustomPropertyFilterDimensions,
	Clauses,
]

/**
 * filter operation
 */
export type FilterOperationWithoutGoals =
	| 'is_not'
	| 'contains_not'
	| 'matches'
	| 'matches_not'

export type Clauses = (string | number)[]

export type FilterWithGoals = [
	FilterOperationWithGoals,
	GoalDimension | SimpleFilterDimensions | CustomPropertyFilterDimensions,
	Clauses,
]

/**
 * filter operation
 */
export type FilterOperationWithGoals = 'is' | 'contains'

export type FilterAndOr = ['and' | 'or', [FilterTree, ...FilterTree[]]]

export type FilterNot = ['not', FilterTree]

export type Metric =
	| 'visitors'
	| 'visits'
	| 'pageviews'
	| 'views_per_visit'
	| 'bounce_rate'
	| 'visit_duration'
	| 'events'
	| 'percentage'
	| 'conversion_rate'
	| 'group_conversion_rate'

export type OrderByEntry = [
	(
		| Metric
		| SimpleFilterDimensions
		| CustomPropertyFilterDimensions
		| TimeDimensions
	),
	'asc' | 'desc',
]

export interface PlausibleQuery {
	/**
	 * Date range to query
	 */
	date_range: DateRangeShorthand | DateTimeRange | DateRange
	/**
	 * What to group the results by. Same as `property` in Plausible API v1
	 */
	dimensions?: Dimensions[]
	/**
	 * How to drill into your data
	 */
	filters?: FilterTree[]
	include?: {
		imports?: boolean
		time_labels?: boolean
		/**
		 * If set, returns the total number of result rows rows before pagination under `meta.total_rows`
		 */
		total_rows?: boolean
	}
	/**
	 * List of metrics to query
	 */
	metrics: [Metric, ...Metric[]]
	/**
	 * How to order query results
	 */
	order_by?: OrderByEntry[]
	pagination?: {
		/**
		 * Number of rows to limit result to.
		 */
		limit?: number
		/**
		 * Pagination offset.
		 */
		offset?: number
	}
	/**
	 * Domain of site to query
	 */
	site_id: string
}

// ------------------------------------------------------------

interface PlausibleResponse<T> {
	results: {
		metrics: number[]
		dimensions: Dimensions[]
	}[]
	// biome-ignore lint/complexity/noBannedTypes: <explanation>
	meta: {}
	query: { site_id: string } & T
}

export const plausibleClient = axios.create({
	baseURL: 'https://plausible.io/api/v2',
	headers: {
		Authorization: `Bearer ${process.env.NEXT_PUBLIC_PLAUSIBLE_API_KEY}`,
	},
})

export const usePlausibleQuery = <T = Omit<PlausibleQuery, 'site_id'>>(
	query: Omit<PlausibleQuery, 'site_id'>,
) => {
	return useQuery({
		queryKey: ['plausible', process.env.NEXT_PUBLIC_PLAUSIBLE_DOMAIN, query],
		queryFn: () =>
			plausibleClient.post<PlausibleResponse<T>>('/query', {
				site_id: process.env.NEXT_PUBLIC_PLAUSIBLE_DOMAIN,
				...query,
			}),
	})
}
