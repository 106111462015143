'use client'

import * as React from 'react'
import { useMemo } from 'react'
import {
	Card,
	CardContent,
	CardDescription,
	CardHeader,
	CardTitle,
} from '~/components/ui/card'
import type { ChartConfig } from '~/components/ui/chart'
import { ScrollArea } from '~/components/ui/scroll-area'
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from '~/components/ui/table'
import { usePlausibleQuery } from '~/lib/plausible-client'
import { usePlausibleFilters } from './use-plausible-filters.hook'

const chartConfig = {
	views: {
		label: 'Visiteurs',
	},
	visitors: {
		label: 'Uniques',
		color: 'hsl(var(--chart-1))',
	},
	total: {
		label: 'Total',
		color: 'hsl(var(--chart-1))',
	},
} satisfies ChartConfig

export const TopReferrer = () => {
	const [filters] = usePlausibleFilters()

	const plausibleQuery = usePlausibleQuery({
		metrics: ['visitors'],
		date_range: [filters.from, filters.to],
		dimensions: ['visit:referrer'],
	})

	const results = plausibleQuery.data?.data.results

	const chartData = useMemo(() => {
		return results
			?.map((r, i) => ({
				referrer: r.dimensions[0],
				visitors: r.metrics[0],
			}))
			.filter((row) => {
				return (
					!row.referrer.includes('cocoon-space.com') &&
					!row.referrer.includes('cocoon-space.dev') &&
					!row.referrer.includes('localhost')
				)
			})
	}, [results])

	console.log({ chartData })

	const total = React.useMemo(
		() => ({
			visitors: chartData?.reduce((acc, curr) => acc + curr.visitors, 0),
		}),
		[chartData],
	)

	return (
		<Card>
			<CardHeader className='flex flex-col items-stretch space-y-0 border-b p-0 sm:flex-row'>
				<div className='flex flex-1 flex-col justify-center gap-1 px-6 py-5 sm:py-6'>
					<CardTitle>Top referrers</CardTitle>

					<CardDescription>
						Referrers les plus fréquents pour la période sélectionnée
					</CardDescription>
				</div>
			</CardHeader>

			<CardContent className='p-0'>
				<Table className='relative'>
					<TableHeader>
						<TableRow>
							<TableHead>Referrer</TableHead>
							<TableHead className='text-right'>Visiteurs</TableHead>
						</TableRow>
					</TableHeader>
				</Table>

				<ScrollArea className='h-[300px]'>
					<Table>
						<TableBody>
							{chartData?.map((row) => (
								<TableRow key={row.referrer}>
									<TableCell>{row.referrer}</TableCell>

									<TableCell className='text-right'>{row.visitors}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</ScrollArea>
			</CardContent>
		</Card>
	)
}
