import { dateTimeUtils } from '@cocoonspace/shared/utils/date-time-utils'
import { parseAsString, useQueryStates } from 'nuqs'

export const usePlausibleFilters = () => {
	return useQueryStates({
		from: parseAsString.withDefault(
			dateTimeUtils().subtract(1, 'month').customFormat('dateTech'),
		),
		to: parseAsString.withDefault(dateTimeUtils().customFormat('dateTech')),
	})
}
