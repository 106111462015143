import { useMemo } from 'react'
import { Pie, PieChart } from 'recharts'
import {
	Card,
	CardDescription,
	CardHeader,
	CardTitle,
} from '~/components/ui/card'
import {
	type ChartConfig,
	ChartContainer,
	ChartLegend,
	ChartLegendContent,
	ChartTooltip,
	ChartTooltipContent,
} from '~/components/ui/chart'
import { usePlausibleQuery } from '~/lib/plausible-client'
import { usePlausibleFilters } from './use-plausible-filters.hook'

const chartConfig = {
	Mobile: {
		label: 'Mobile',
		color: 'hsl(var(--chart-1))',
	},
	Desktop: {
		label: 'Desktop',
		color: 'hsl(var(--chart-2))',
	},
	Tablet: {
		label: 'Tablet',
		color: 'hsl(var(--chart-3))',
	},
	'(not set)': {
		label: '(not set)',
		color: 'hsl(var(--chart-4))',
	},
} satisfies ChartConfig

export const Devices = () => {
	const [filters] = usePlausibleFilters()

	const plausibleQuery = usePlausibleQuery({
		date_range: [filters.from, filters.to],
		metrics: ['visitors'],
		dimensions: ['visit:device'],
	})

	const plausibleRes = plausibleQuery.data?.data.results || []

	const chartData = useMemo(() => {
		const total = plausibleRes.reduce((acc, { metrics }) => acc + metrics[0], 0)

		return (
			plausibleRes.map(({ metrics, dimensions }, index) => {
				return {
					label: dimensions[0],
					value: metrics[0],
					valuePercent: Math.round((metrics[0] / total) * 100),
					fill: `hsl(var(--chart-${index + 1}))`,
				}
			}) || []
		)
	}, [plausibleRes])

	return (
		<Card className='flex-1'>
			<CardHeader className='flex flex-col items-stretch space-y-0 border-b p-0 sm:flex-row'>
				<div className='flex flex-1 flex-col justify-center gap-1 px-6 py-5 sm:py-6'>
					<CardTitle>Devices</CardTitle>

					<CardDescription>
						Devices utilisés par les utilisateurs pour la période sélectionnée
						en pourcentage
					</CardDescription>
				</div>
			</CardHeader>

			<ChartContainer
				config={chartConfig}
				className='mt-8 mb-4 h-[250px] w-full'
			>
				<PieChart>
					<Pie
						data={chartData}
						innerRadius={40}
						outerRadius={80}
						dataKey='valuePercent'
					/>

					<ChartTooltip
						content={
							<ChartTooltipContent
								className='w-[150px]'
								nameKey='label'
							/>
						}
					/>

					<ChartLegend content={<ChartLegendContent nameKey='label' />} />
				</PieChart>
			</ChartContainer>
		</Card>
	)
}
