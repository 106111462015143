import { useMemo } from 'react'
import { Pie, PieChart } from 'recharts'
import {
	Card,
	CardDescription,
	CardHeader,
	CardTitle,
} from '~/components/ui/card'
import {
	type ChartConfig,
	ChartContainer,
	ChartLegend,
	ChartLegendContent,
	ChartTooltip,
	ChartTooltipContent,
} from '~/components/ui/chart'
import { usePlausibleQuery } from '~/lib/plausible-client'
import { usePlausibleFilters } from './use-plausible-filters.hook'

const chartConfig = {
	Chrome: {
		label: 'Chrome',
	},
	Safari: {
		label: 'Safari',
	},
	'Microsoft Edge': {
		label: 'Microsoft Edge',
	},
	Firefox: {
		label: 'Firefox',
	},
	Others: {
		label: 'Autres',
	},
} satisfies ChartConfig

export const Browsers = () => {
	const [filters] = usePlausibleFilters()

	const plausibleQuery = usePlausibleQuery({
		date_range: [filters.from, filters.to],
		metrics: ['visitors'],
		dimensions: ['visit:browser'],
	})

	const plausibleRes = plausibleQuery.data?.data.results || []

	const chartData = useMemo(() => {
		const total = plausibleRes.reduce((acc, { metrics }) => acc + metrics[0], 0)

		return plausibleRes
			.map((row) => ({
				label: row.dimensions[0],
				value: row.metrics[0],
			}))
			.reduce(
				(acc, row, index) => {
					const isCommonBrowser = [
						'Chrome',
						'Safari',
						'Microsoft Edge',
						'Firefox',
					].includes(row.label)

					if (isCommonBrowser) {
						acc.push({
							label: row.label,
							value: row.value,
							valuePercent: Math.round((row.value / total) * 100),
							fill: `hsl(var(--chart-${index + 1}))`,
						})
					} else {
						const othersEl = acc.find(({ label }) => label === 'Others')

						if (othersEl) {
							othersEl.value += row.value
							othersEl.valuePercent = Math.round((othersEl.value / total) * 100)
						} else {
							acc.push({
								label: 'Others',
								value: row.value,
								valuePercent: Math.round((row.value / total) * 100),
								fill: 'hsl(var(--chart-5))',
							})
						}
					}

					return acc
				},
				[] as {
					label: string
					value: number
					valuePercent: number
					fill: string
				}[],
			)
	}, [plausibleRes])

	return (
		<Card className='flex-1'>
			<CardHeader className='flex flex-col items-stretch space-y-0 border-b p-0 sm:flex-row'>
				<div className='flex flex-1 flex-col justify-center gap-1 px-6 py-5 sm:py-6'>
					<CardTitle>Navigateurs</CardTitle>

					<CardDescription>
						Navigateurs utilisés par les utilisateurs pour la période
						sélectionnée en pourcentage
					</CardDescription>
				</div>
			</CardHeader>

			<ChartContainer
				config={chartConfig}
				className='mt-8 mb-4 h-[250px] w-full'
			>
				<PieChart>
					<Pie
						data={chartData}
						innerRadius={40}
						outerRadius={80}
						dataKey='valuePercent'
					/>

					<ChartTooltip
						content={
							<ChartTooltipContent
								className='w-[150px]'
								nameKey='label'
							/>
						}
					/>

					<ChartLegend content={<ChartLegendContent nameKey='label' />} />
				</PieChart>
			</ChartContainer>
		</Card>
	)
}
