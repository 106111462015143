import { RangePicker } from '~/components/ui/range-picker'
import { usePlausibleFilters } from './use-plausible-filters.hook'

export const PlausibleFilters = () => {
	const [filters, setFilters] = usePlausibleFilters()

	return (
		<div className='flex items-center gap-4'>
			<RangePicker
				value={{
					start: filters.from,
					end: filters.to,
				}}
				onChange={(value) => {
					setFilters({
						from: value?.start || null,
						to: value?.end || null,
					})
				}}
			/>
		</div>
	)
}
