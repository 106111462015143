import { Browsers } from './browsers'
import { Devices } from './devices'
import { PlausibleFilters } from './plausible-filters'
import { TopReferrer } from './top-referrer'
import { Visitors } from './visitors'

export const PlausiblePage = () => {
	return (
		<div className='flex flex-col gap-4'>
			<PlausibleFilters />

			<Visitors />

			<div className='flex flex-col gap-4 md:flex-row'>
				<Browsers />
				<Devices />
			</div>

			<TopReferrer />
		</div>
	)
}
