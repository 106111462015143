'use client'

import * as React from 'react'
import { useMemo } from 'react'
import { Area, AreaChart, CartesianGrid, XAxis } from 'recharts'
import {
	Card,
	CardContent,
	CardDescription,
	CardHeader,
	CardTitle,
} from '~/components/ui/card'
import {
	type ChartConfig,
	ChartContainer,
	ChartTooltip,
	ChartTooltipContent,
} from '~/components/ui/chart'
import { usePlausibleQuery } from '~/lib/plausible-client'
import { usePlausibleFilters } from './use-plausible-filters.hook'

const chartConfig = {
	views: {
		label: 'Visiteurs',
	},
	visitors: {
		label: 'Uniques',
		color: 'hsl(var(--chart-1))',
	},
	total: {
		label: 'Total',
		color: 'hsl(var(--chart-1))',
	},
} satisfies ChartConfig

export const Visitors = () => {
	const [filters] = usePlausibleFilters()

	const plausibleQuery = usePlausibleQuery({
		metrics: ['visitors'],
		date_range: [filters.from, filters.to],
		dimensions: ['time:day'],
		include: { time_labels: true },
	})

	const plausibleQueryTotal = usePlausibleQuery({
		metrics: ['visits'],
		date_range: [filters.from, filters.to],
		dimensions: ['time:day'],
		include: { time_labels: true },
	})

	const results = plausibleQuery.data?.data.results

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	const chartData = useMemo(() => {
		return results?.map((r, i) => ({
			date: r.dimensions[0],
			visitors: r.metrics[0],
			total: plausibleQueryTotal.data?.data.results[i].metrics[0] ?? 0,
		}))
	}, [results])

	const total = React.useMemo(
		() => ({
			visitors: chartData?.reduce((acc, curr) => acc + curr.visitors, 0),
			total: chartData?.reduce((acc, curr) => acc + curr.total, 0),
		}),
		[chartData],
	)

	return (
		<Card>
			<CardHeader className='flex flex-col items-stretch space-y-0 border-b p-0 sm:flex-row'>
				<div className='flex flex-1 flex-col justify-center gap-1 px-6 py-5 sm:py-6'>
					<CardTitle>Visites</CardTitle>

					<CardDescription>
						Nombre de visiteurs pour la période sélectionnée
					</CardDescription>
				</div>

				<div className='flex'>
					{['visitors', 'total'].map((key) => {
						const chart = key as keyof typeof chartConfig

						return (
							<div
								key={chart}
								className='relative z-30 flex flex-1 flex-col justify-center gap-1 border-t px-6 py-4 text-left even:border-l data-[active=true]:bg-muted/50 sm:border-t-0 sm:border-l sm:px-8 sm:py-6'
							>
								<span className='text-muted-foreground text-xs'>
									{chartConfig[chart].label}
								</span>

								<span className='font-bold text-lg leading-none sm:text-3xl'>
									{total[key as keyof typeof total]?.toLocaleString('fr')}
								</span>
							</div>
						)
					})}
				</div>
			</CardHeader>

			<CardContent className='px-2 sm:p-6'>
				<ChartContainer
					config={chartConfig}
					className='aspect-auto h-[250px] w-full'
				>
					<AreaChart
						accessibilityLayer
						data={chartData}
						margin={{
							left: 12,
							right: 12,
						}}
					>
						<defs>
							<linearGradient
								id='fillUnique'
								x1='0'
								y1='0'
								x2='0'
								y2='1'
							>
								<stop
									offset='5%'
									stopColor='hsl(var(--chart-1))'
									stopOpacity={1}
								/>
								<stop
									offset='95%'
									stopColor='hsl(var(--chart-1))'
									stopOpacity={0.1}
								/>
							</linearGradient>

							<linearGradient
								id='fillTotal'
								x1='0'
								y1='0'
								x2='0'
								y2='1'
							>
								<stop
									offset='5%'
									stopColor='hsl(var(--chart-3))'
									stopOpacity={0.7}
								/>
								<stop
									offset='95%'
									stopColor='hsl(var(--chart-3))'
									stopOpacity={0.1}
								/>
							</linearGradient>
						</defs>

						<CartesianGrid vertical={false} />

						<XAxis
							dataKey='date'
							tickLine={false}
							axisLine={false}
							tickMargin={8}
							minTickGap={32}
							tickFormatter={(value) => {
								const date = new Date(value)

								return date.toLocaleDateString('fr', {
									month: 'short',
									day: 'numeric',
								})
							}}
						/>

						<ChartTooltip
							content={
								<ChartTooltipContent
									className='w-[150px]'
									labelFormatter={(value) => {
										return new Date(value).toLocaleDateString('fr', {
											month: 'short',
											day: 'numeric',
											year: 'numeric',
										})
									}}
								/>
							}
						/>

						<Area
							dataKey='total'
							stroke='hsl(var(--chart-3))'
							fill='url(#fillTotal)'
							fillOpacity={0.2}
						/>

						<Area
							dataKey='visitors'
							stroke='hsl(var(--chart-1))'
							fill='url(#fillUnique)'
						/>
					</AreaChart>
				</ChartContainer>
			</CardContent>
		</Card>
	)
}
